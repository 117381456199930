// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import NotFound from './NotFound'; // Import the NotFound component

// Importing the logo image
import logo from './assets/logo.svg';

// Importing images from the assets folder
import screenshot1 from './assets/screenshot1.png';
import screenshot2 from './assets/screenshot2.png';
import screenshot3 from './assets/screenshot3.png';
import screenshot4 from './assets/screenshot4.png';

// Color Variables
const mainColor = '#01A59C';
const secondaryColor = '#0C6667';
const accentColor1 = '#F8AD89';

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: ${secondaryColor};
`;

const Header = styled.header`
  background-color: ${mainColor};
  color: white;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  margin: 0;

  img {
    height: 40px;
    vertical-align: middle;
  }
`;

const Banner = styled.section`
  background-color: ${accentColor1};
  color: ${secondaryColor};
  text-align: center;
  padding: 3em 1em;

  @media (max-width: 768px) {
    padding: 2em 1em;
  }
`;

const Headline = styled.h2`
  font-size: 2.5em;
  margin: 0.5em 0;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const SubHeadline = styled.p`
  font-size: 1.2em;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const DownloadButton = styled.button`
  padding: 0.75em 1.5em;
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 2em;
  color: white;
  background-color: ${mainColor};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${secondaryColor};
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1em;
  }
`;

const ScreenshotContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2em;

  @media (max-width: 768px) {
    display: none;
  }

  img {
    width: 200px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

const CarouselContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Footer = styled.footer`
  padding: 1em;
  background-color: ${secondaryColor};
  color: white;
  text-align: center;

  a {
    color: white;
  }
`;

const App = () => {
  const handleDownload = () => {
    const downloadUrl = '/forward-sms-1.0.apk';
    window.location.href = downloadUrl;
  };
 
  return (
    <Router>
      <Container>
        <Header>
          <Logo><img src={logo} alt="Forward SMS Logo" /> Forward SMS </Logo>
        </Header>
        <Routes>
          <Route
            path="/"
            element={
              <Banner>
                <Headline>Forward SMS - Stay Connected</Headline>
                <SubHeadline>Forward your important messages easily and stay informed.</SubHeadline>
                <DownloadButton onClick={handleDownload}>Download for Android</DownloadButton>
                <ScreenshotContainer>
                  <img src={screenshot1} alt="Screenshot of app showing splash screen" />
                  <img src={screenshot2} alt="Screenshot of app showing main dashboard" />
                  <img src={screenshot3} alt="Screenshot of app Add Settings" />
                  <img src={screenshot4} alt="Screenshot of app utils" />
                </ScreenshotContainer>
                <CarouselContainer>
                  <Carousel
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    interval={3000}
                    stopOnHover
                    showArrows
                    showStatus={false}
                    ariaLabel="App screenshots carousel"
                  >
                    <div>
                      <img src={screenshot1} alt="Screenshot of app showing splash screen" />
                    </div>
                    <div>
                      <img src={screenshot2} alt="Screenshot of app showing main dashboard" />
                    </div>
                    <div>
                      <img src={screenshot3} alt="Screenshot of app Add Settings" />
                    </div>
                    <div>
                      <img src={screenshot4} alt="Screenshot of app utilis" />
                    </div>
                  </Carousel>
                </CarouselContainer>
              </Banner>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer id="contact">
          <p>Contact us at <a href="mailto:contact@forwardsms.com">info@knightcoder.in</a></p>
        </Footer>
      </Container>
    </Router>
  );
};

export default App;
