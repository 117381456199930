// src/NotFound.js

import React from 'react';

const NotFound = () => {
  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div style={styles.error}>
          <h1 style={styles.heading}>404</h1>
          <p style={styles.message}>Oops! The page you are looking for does not exist.</p>
          <a href="/" style={styles.backHome}>Go Back Home</a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  body: {
    margin: 0,
    padding: 0,
    fontFamily: "'Arial', sans-serif",
    background: '#f2f2f2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: '#333',
  },
  container: {
    textAlign: 'center',
    background: 'white',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '90%',
    margin: '20px',
  },
  error: {},
  heading: {
    fontSize: '96px',
    margin: 0,
    color: '#01A59C',
  },
  message: {
    fontSize: '18px',
    margin: '20px 0',
    color: '#666',
  },
  backHome: {
    display: 'inline-block',
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: '#01A59C',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  },
};

styles.backHome[':hover'] = {
  backgroundColor: '#ff4c4c',
};

export default NotFound;